import React, {useEffect} from 'react';
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import locales_es from "../../locales/es";
import Spinner from "../spinner";
import {useHistory} from "react-router-dom";
import {
  EVENT_SHOW_PLAN_ALERT, EVENT_SHOW_PLAN_EFFECT,
  HREF_PAGE_PLANS,
  PLAN_TAGS,
  USER_TYPE_MEDIC,
  USER_TYPE_SECRETARY
} from "../../models/constants";
import AuthService from "../../modules/authService";

const PlanButton = ({onIsBonusPlan}) => {
  const api = new APIService();
  const helpers = new Helpers();
  const auth = new AuthService();

  const history = useHistory();

  const [plan, setPlan] = React.useState(null);

  const classMap = {
    free: 'badge-success',
    enterprise: 'badge-info',
    pro: 'badge-warning',
  };

  const checkSubscription = () => {
    if (auth.getLocalUserType() === USER_TYPE_SECRETARY && plan && plan.tag === PLAN_TAGS.FREE) {
      alert('Tu suscripción ha caducado, por favor actualiza tu plan para seguir utilizando la aplicación.');
      const event = new CustomEvent(EVENT_SHOW_PLAN_ALERT);
      window.dispatchEvent(event);
    }
  }
  useEffect(() => {
    checkSubscription();
  }, [plan]);

  useEffect(() => {
    api.getMyPlan().then(res => {
      setPlan(res.data);
      if (res.data?.is_bonus_plan) {
        onIsBonusPlan && onIsBonusPlan(res.data.is_bonus_plan);
      }
      if (res.data?.tag === PLAN_TAGS.PRO || res.data?.tag === PLAN_TAGS.ENTERPRISE) {
        const event = new CustomEvent(EVENT_SHOW_PLAN_EFFECT);
        window.dispatchEvent(event);
      }
    }).catch(err => {
      console.log(locales_es.errorModal.title, helpers.getErrorMsg(err));
    });
  }, []);

  return (
    plan === null ? <Spinner/> : plan ? <>
      <button onClick={(e) => {
        e.preventDefault();
        if (window.offcanvas && window.offcanvas.hide) {
          window.offcanvas.hide();
        }
        auth.getLocalUserType() === USER_TYPE_MEDIC ? history.push(HREF_PAGE_PLANS) : null;
      }} title={plan.description}
              className={`badge ${classMap[plan.tag] || 'badge-danger'} border-0`}>Plan {plan.name}</button>
        {plan?.is_bonus_plan && <button className="ml-2 badge badge-success border-0">{locales_es.discounted}</button>}
      </>
      : <button className="badge badge-danger border-0">Sin Plan</button>
  );
};

export default PlanButton;
