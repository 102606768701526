import React, {Component} from "react";
import {
  hrefLogin, PAYMENT_METHOD_BANK_TRANSFER_ARS, PAYMENT_METHOD_BANK_TRANSFER_USD,
  PAYMENT_METHOD_MERCADO_PAGO_ARS, PAYMENT_METHOD_PAYPAL_USD, STATUS_FAILED,
  STATUS_SUCCESS,
  USER_TYPE_MEDIC
} from "../../models/constants";
import AuthService from "../../modules/authService";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import locales_es from "../../locales/es";
import Spinner from "../../components/spinner";
import "./styles.css";
import Loading from "../../components/loading";
import ModalAnimationResult from "../../components/modalAnimationResult";
import Form from "../../components/form";
import PrescriptionSettings from "../../components/prescriptionSettings";
import AttentionPlaces from "../../components/attentionPlaces";

export default class SettingsPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedTab: this.getPreselectedTab(),
      loading: true,
      userData: null,
      paymentMethods: null,
      enableBeforePayment: null,
      mercadoPagoEnabled: null,
      mercadoPagoData: null,
      result: null,
      amountTypes: null,
      currentAmountType: null,
      currentPaymentMethods: [],
      bankTransferData: '',
      enabledInterruptedAgenda: false,
      enabledInterruptedAgendaForNoPatientsOnly: false,
      interruptedAgendaForNoPatientsOnly: '',
      currencies: null,
      paypalCredentials: null,
      bankTransferDataUSD: '',
    };

    this.auth = new AuthService();
    this.api = new APIService();
    this.helpers = new Helpers();

    if (window.URLSearchParams && new window.URLSearchParams(this.props.location.search).get("result")) {
      setTimeout(() => {
        this.setState({
          result: new window.URLSearchParams(this.props.location.search).get("result")
        })
      }, 1500);
    }
  }

  componentWillMount() {
    this.checkUserStatus();
  }

  componentDidMount() {
    if (this.state.selectedTab) {
      const anchor = document.getElementById(`a-tab${this.state.selectedTab}`);
      if (anchor) {
        anchor.click();
      }
    }
  }

  getPreselectedTab() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlTab = urlParams.get('tab');
    if (urlTab) {
      return urlTab;
    }

    const storedTab = localStorage.getItem('tab');
    if (storedTab) {
      return storedTab;
    }
  }

  saveTabToLocalStorage(tabValue) {
    localStorage.setItem('tab', tabValue);
  }

  handleTabClick = (event) => {
    event.preventDefault();
    const tabNumber = event.currentTarget.getAttribute('href').split('#tab')[1];
    this.saveTabToLocalStorage(tabNumber);
  }

  setLoading(bool) {
    this.setState({
      loading: bool
    })
  }

  async checkUserStatus() {
    const isLoggedIn = await this.auth.isLoggedUser();
    const userData = await this.auth.getRemoteUserData();
    if (!isLoggedIn || (isLoggedIn && userData && userData.user && userData.user.user_type !== USER_TYPE_MEDIC)) {
      this.redirectNotLoggedIn();
    } else {
      this.load();
    }
  }

  load() {
    this.api.getCurrencies().then(res => {
      this.setState({
        currencies: res.data
      })
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    })
    this.api.getPaymentMethods().then(res => {
      if (res && res.data) {
        this.setState({
          paymentMethods: res.data
        }, () => {
          this.loadUserData();
          this.getMercadoPagoStatus();
          this.getBankTransferInfo();
          this.getPaypalStatus();
          this.getBankTransferUSDInfo();
        });
      }
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });

    this.api.getPaymentsAmountTypes().then(res => {
      this.setState({
        amountTypes: res.data
      })
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }

  loadUserData() {
    this.auth.getRemoteUserData().then(res => {
      this.setState({
        userData: res.data.user,
        enableBeforePayment: res.data.user.enable_before_payment,
        beforePaymentType: res.data.user.before_payment_type,
        currentAmountType: res.data.user.before_payment_type,
        currentAmount: res.data.user.before_payment_amount,
        beforePaymentAmount: res.data.user.before_payment_amount,
        interruptedAgenda: res.data.user.interrupted_agenda,
        enabledInterruptedAgenda: !!res.data.user.interrupted_agenda,
        enabledInterruptedAgendaForNoPatientsOnly: !!res.data.user.interrupted_agenda_only_patients,
        interruptedAgendaForNoPatientsOnly: res.data.user.interrupted_agenda_only_patients_message,
      }, () => {
        this.loadUserPaymentMethods();
        this.setLoading(false);
      });
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    })
  }

  loadUserPaymentMethods() {
    this.api.getPaymentMethodsByUser(this.state.userData.id).then(res => {
      const arr = res.data;
      this.setState({
        currentPaymentMethods: arr.map(pm => pm.payment_method.id)
      })
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }

  PMIndexInArray(pmId) {
    return this.state.currentPaymentMethods.indexOf(pmId);
  }

  redirectNotLoggedIn() {
    window.location.href = `${hrefLogin}?redirect=${window.location.pathname}`;
  }

  getBankTransferInfo() {
    this.api.getBankTransfer().then(res => {
      if (res.data) {
        this.setState({
          bankTransferData: res.data.data
        })
      }
    }).catch((err) => {
      // this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      console.log(err)
    })
  }

  getBankTransferUSDInfo() {
    this.api.getBankTransferUSD().then(res => {
      if (res.data) {
        this.setState({
          bankTransferDataUSD: res.data.data
        })
      }
    }).catch((err) => {
      // this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      console.log(err)
    })
  }

  getMercadoPagoStatus() {
    this.api.getMercadoPagoInfo().then((res) => {
      this.setMercadoPagoEnabled(true);
      this.setState({
        mercadoPagoData: res.data
      });
    }).catch(() => {
      // this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      this.setMercadoPagoEnabled(false);
    })
  }

  getPaypalStatus() {
    this.api.getPaypalInfo().then((res) => {
      this.setState({
        paypalCredentials: res.data
      });
    }).catch((err) => {
      console.log(err);
      // this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      this.setState({
        paypalCredentials: false
      });
    })
  }

  setPaypalCredentials(key, value) {
    const paypalCredentials = JSON.parse(JSON.stringify(this.state.paypalCredentials));
    paypalCredentials[key] = value;
    this.setState({
      paypalCredentials
    });
  }

  setMercadoPagoEnabled(bool) {
    this.setState({
      mercadoPagoEnabled: bool
    })
  }

  handleChange = state => ev => {
    this.setState({[state]: ev.target.value});
  };

  handleReactSelectChange = state => value => {
    this.setState({[state]: value});
  };

  sendInterruptedAgenda(silentMode) {
    if (!this.state.interruptedAgenda && !silentMode) {
      this.props.showMainModal(locales_es.infoModal.title, locales_es.interruptedAgendaTextMandatory);
      return;
    }
    this.api.putInterruptedAgenda(this.state.userData.id, {interrupted_agenda: this.state.interruptedAgenda}).then(res => {
      if (!silentMode) {
        this.props.showMainModal(locales_es.successModal.title, res.message);
      }
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }

  sendInterruptedAgendaForNoPatientsOnly() {
    this.api.putInterruptedAgendaOnlyPatients(this.state.userData.id, {
      interrupted_agenda_only_patients: this.state.enabledInterruptedAgendaForNoPatientsOnly,
      interrupted_agenda_only_patients_message: this.state.interruptedAgendaForNoPatientsOnly
    }).then(res => {
      this.props.showMainModal(locales_es.successModal.title, res.message);
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }

  render() {
    const {
      loading,
      paymentMethods,
      userData,
      enableBeforePayment,
      mercadoPagoEnabled,
      mercadoPagoData,
      result,
      enabledInterruptedAgenda,
      enabledInterruptedAgendaForNoPatientsOnly,
      currencies,
      paypalCredentials,
    } = this.state;

    const onChangeEnableBeforePayment = () => {
      this.setState({
        enableBeforePayment: !enableBeforePayment
      })
    };

    const save = (ev) => {
      ev.preventDefault();
      sendConfig({enable_before_payment: enableBeforePayment}, enableBeforePayment);
    };

    const sendConfig = (objData, sendPM) => {
      this.setLoading(true);
      this.api.putPaymentConfig(objData).then((res) => {
        if (sendPM) {
          sendPaymentMethods();
        } else {
          this.props.showMainModal(locales_es.successModal.title, res.message);
          this.setLoading(false);
        }
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        this.setLoading(false);
      });
    };

    const sendPaymentMethods = () => {
      this.setLoading(true);
      this.api.putBankTransferUSDData({data: this.state.bankTransferDataUSD}).then(() => {
        this.api.putBankTransferData({data: this.state.bankTransferData}).then(() => {
          sendPaypalCredentials();
        }).catch(err => {
          this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
          this.setLoading(false);
        });
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        this.setLoading(false);
      });
    };

    const sendPaypalCredentials = () => {
      if (this.state.paypalCredentials) {
        this.api.putPaypalData(this.state.paypalCredentials).then(() => {
          confirmPaymentMethods();
        }).catch(err => {
          this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
          confirmPaymentMethods();
        })
      } else {
        confirmPaymentMethods();
      }
    }

    const confirmPaymentMethods = () => {
      this.api.putPaymentMethods({payment_methods: this.state.currentPaymentMethods}).then(res => {
        this.props.showMainModal(locales_es.successModal.title, res.message);
        this.load();
        this.setLoading(false);
      }).catch((err) => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        this.setLoading(false);
      })
    }

    const onClickCheckbox = (e, pm) => {
      if (!enableBeforePayment) {
        e.preventDefault();
      }
      if (pm.tag === PAYMENT_METHOD_MERCADO_PAGO_ARS && !mercadoPagoEnabled) {
        this.props.showMainModal(locales_es.infoModal.title, 'Debe vincular primero una cuenta Mercado Pago para poder usar este medio de cobro');
        return;
      }

      const paymentMethods = JSON.parse(JSON.stringify(this.state.currentPaymentMethods));
      // console.log('paymentMethods:');
      // console.log(paymentMethods);
      const index = this.PMIndexInArray(pm.id);
      if (index >= 0) {
        paymentMethods.splice(index, 1);
        this.setState({
          currentPaymentMethods: paymentMethods
        })
      } else {
        paymentMethods.push(pm.id);
        this.setState({
          currentPaymentMethods: paymentMethods
        })
      }
    };

    const onClickLinkMercadoPago = () => {
      this.api.getMercadoPagoAuthorizationLink(window.location.origin).then(res => {
        window.location.href = res.data.url;
        // window.open(res.data.url, '_blank');
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      })
    };

    const onClickUnlinkMercadoPago = () => {
      const userData = this.auth.getUserData();
      // Inhabilitación hardcoded para la clínica https://api.medicloud.com.ar/admin/clinics/id/740 en la cuenta 13790
      if (userData.user?.id === 13790) {
        this.props.showMainModal('Acción deshabilitada', 'No puedes hacer cambios a la configuración. Contacta con el administrador de tu cuenta.')
        return;
      }
      this.props.showMainModal('Desvincular cuenta de Mercado Pago', '¿Está seguro de desvincular su cuenta de Mercado Pago?', null, [
        {
          label: 'Sí, desvincular',
          class: 'btn btn-danger btn-elevate btn-pill btn-sm',
          method: () => {
            this.api.deleteMercadoPagoAuthorization().then(res => {
              this.props.showMainModal(locales_es.successModal.title, res.message);
              this.setState({
                mercadoPagoEnabled: false
              })
            }).catch(err => {
              this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
            })
          }
        },
        {
          label: 'No, no desvincular',
          class: 'btn btn-primary btn-elevate btn-pill btn-sm'
        }
      ]);
    };

    const onBlurBankTransferTextarea = (pmId) => {
      if (this.PMIndexInArray(pmId) >= 0 && !this.state.bankTransferData) {
        this.props.showMainModal(locales_es.infoModal.title, 'Debes completar la información adicional en el campo de texto para activar el cobro mediante Transferencia Bancaria en Pesos Argentinos.');
      }
    };

    const onBlurBankTransferUSDTextarea = (pmId) => {
      if (this.PMIndexInArray(pmId) >= 0 && !this.state.bankTransferDataUSD) {
        this.props.showMainModal(locales_es.infoModal.title, 'Debes completar la información adicional en el campo de texto para activar el cobro mediante Transferencia Bancaria en USD.');
      }
    };

    const toggleInterruptedAgenda = () => {
      const toggle = !this.state.enabledInterruptedAgenda;
      this.setState({
        enabledInterruptedAgenda: toggle,
        interruptedAgenda: toggle === false ? '' : this.state.interruptedAgenda,
      }, () => {
        if (toggle === false) {
          this.sendInterruptedAgenda(true);
        }
      });
    }

    const interruptedAgendaInputs = [
      {
        label: locales_es.interruptedAgendaText,
        placeholder: locales_es.interruptedAgendaTextPlaceholder,
        id: 6,
        state: 'interruptedAgenda',
        value: this.state.interruptedAgenda,
        type: 'textarea',
        required: false,
        wrapperCustomClassName: 'form-group clear',
      },
    ];

    const toggleInterruptedAgendaForNoPatientsOnly = () => {
      const toggle = !this.state.enabledInterruptedAgendaForNoPatientsOnly;
      this.setState({
        enabledInterruptedAgendaForNoPatientsOnly: toggle,
      }, () => {
        if (toggle === false && this.state.userData.interrupted_agenda_only_patients) {
          this.sendInterruptedAgendaForNoPatientsOnly();
        }
      });
    }

    const interruptedAgendaForNoPatientsOnlyInputs = [
      {
        label: locales_es.interruptedAgendaForNoPatientsOnlyText,
        placeholder: locales_es.interruptedAgendaForNoPatientsOnlyTextPlaceholder,
        id: 7,
        state: 'interruptedAgendaForNoPatientsOnly',
        value: this.state.interruptedAgendaForNoPatientsOnly,
        type: 'textarea',
        required: false,
        wrapperCustomClassName: 'form-group clear',
      },
    ];

    return (
      <div className="kt-portlet kt-portlet--height-fluid kt-widget-17">
        {loading ? <Loading/> : null}
        <div className="row">
          <div className="col-12">

            <div className="kt-portlet kt-portlet--tabs m-0">
              <div className="kt-portlet__head">
                <div className="kt-portlet__head-label d-none d-lg-flex">
                  <h3 className="kt-portlet__head-title">
                    {locales_es.settings}
                  </h3>
                </div>
                <div className="kt-portlet__head-toolbar">
                  <ul className="nav nav-tabs nav-tabs-line nav-tabs-line-brand nav-tabs-bold"
                      role="tablist">
                    <li className="nav-item">
                      <a className="nav-link show active" data-toggle="tab" onClick={e => this.handleTabClick(e)}
                         href="#tab1" id="a-tab1" role="tab" aria-selected="true">
                        {locales_es.agenda}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link show" data-toggle="tab" onClick={e => this.handleTabClick(e)}
                         href="#tab2" id="a-tab2" role="tab" aria-selected="true">
                        {locales_es.gatewayMethods}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link show" data-toggle="tab" onClick={e => this.handleTabClick(e)}
                         href="#tab3" id="a-tab3" role="tab" aria-selected="true">
                        {locales_es.prescriptions}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link show" data-toggle="tab" onClick={this.handleTabClick}
                         href="#tab4" id="a-tab4" role="tab" aria-selected="true">
                        {locales_es.attentionPlaces}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="kt-portlet__body">
                <div className="tab-content">
                  <div className="tab-pane fade active show" id="tab1" role="tabpanel">

                    <div className="row mt-3">
                      <div className="col">
                        <label className="kt-checkbox">
                          <input type="checkbox"
                                 onChange={toggleInterruptedAgenda}
                                 checked={enabledInterruptedAgenda}/>
                          <span/> Interrumpir agenda por completo
                        </label>
                      </div>
                    </div>

                    {enabledInterruptedAgenda &&
                      <div className="kt-portlet">
                        <div className="row mt-5 pt-3">
                          <div className="col text-center">
                            <h3>{locales_es.interruptedAgendaTitle}</h3>
                            <p>{locales_es.interruptedAgendaTextDescription}</p>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col text-center">
                            <Form
                              styles="kt-form"
                              inputs={interruptedAgendaInputs}
                              handleChange={this.handleChange}
                              wrapper={true}
                            />
                          </div>
                        </div>
                        <div className="row m-4">
                          <div className="col text-center">
                            <button type="button"
                                    onClick={() => this.sendInterruptedAgenda(false)}
                                    className="btn btn-warning btn-elevate btn-pill mr-3">
                              <i className="flaticon-danger"/> {locales_es.interruptedAgendaSend}</button>
                          </div>
                        </div>
                      </div>
                    }

                    <div className="row mt-3">
                      <div className="col">
                        <label className="kt-checkbox">
                          <input type="checkbox"
                                 onChange={toggleInterruptedAgendaForNoPatientsOnly}
                                 checked={enabledInterruptedAgendaForNoPatientsOnly}/>
                          <span/> Interrumpir agenda SÓLO para Pacientes Nuevos
                        </label>
                      </div>
                    </div>

                    {enabledInterruptedAgendaForNoPatientsOnly &&
                      <div className="kt-portlet">
                        <div className="row mt-5 pt-3">
                          <div className="col text-center">
                            <h3>{locales_es.interruptedAgendaForNoPatientsOnlyTitle}</h3>
                            <p>{locales_es.interruptedAgendaForNoPatientsOnlyTextDescription}</p>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col text-center">
                            <Form
                              styles="kt-form"
                              inputs={interruptedAgendaForNoPatientsOnlyInputs}
                              handleChange={this.handleChange}
                              wrapper={true}
                            />
                          </div>
                        </div>
                        <div className="row m-4">
                          <div className="col text-center">
                            <button type="button"
                                    onClick={() => this.sendInterruptedAgendaForNoPatientsOnly()}
                                    className="btn btn-warning btn-elevate btn-pill mr-3">
                              <i className="flaticon-danger"/> {locales_es.interruptedAgendaForNoPatientsOnlySend}
                            </button>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                  <div className="tab-pane fade" id="tab2" role="tabpanel">

                    {currencies === null ? <Spinner/> :
                      currencies && currencies.length ?
                        <div className="kt-portlet--tabs m-0">
                          <div className="row mt-3">
                            <div className="col">
                              <label className="kt-checkbox">
                                <input type="checkbox" id="enable-before-payment"
                                       onChange={onChangeEnableBeforePayment}
                                       checked={enableBeforePayment}/>
                                <span/> Habilitar cobro online
                              </label>
                            </div>
                          </div>
                          <div className="kt-portlet__head">
                            <div className="kt-portlet__head-toolbar">
                              <ul className="nav nav-tabs nav-tabs-line nav-tabs-line-brand nav-tabs-bold"
                                  role="tablist">
                                {currencies.map((currency, index) =>
                                  <li className="nav-item">
                                    <a className={`nav-link show ${index === 0 ? 'active' : ''}`} data-toggle="tab"
                                       href={`#tab-currencies-${currency.id}`} id={`a-tab-currencies-${currency.id}`}
                                       role="tab" aria-selected="true">
                                      {currency.id.toUpperCase()}
                                    </a>
                                  </li>)}
                              </ul>
                            </div>
                          </div>
                          <div className="kt-portlet__body">
                            <div className="tab-content">
                              {currencies.map((currency, index) =>
                                <div className={`tab-pane fade ${index === 0 ? 'active show' : ''}`}
                                     id={`tab-currencies-${currency.id}`}
                                     role="tabpanel">
                                  {userData === null ? <Spinner/> :
                                    userData ?
                                      <>
                                        {paymentMethods === null ?
                                          <Spinner/>
                                          : paymentMethods && paymentMethods.length ?
                                            paymentMethods
                                              .filter((pm) => currency.enabled_payment_methods.includes(pm.tag))
                                              .map(pm =>
                                                <div key={pm.id}
                                                     className={`row mt-3 ml-3 border-top ${enableBeforePayment ? '' : 'disabled'}`}>
                                                  <div className="col">
                                                    <label className="kt-checkbox">
                                                      <input type="checkbox" id={pm.id}
                                                             onChange={(e) => onClickCheckbox(e, pm)}
                                                             checked={this.PMIndexInArray(pm.id) >= 0}
                                                             // disabled={enableBeforePayment && pm.tag === PAYMENT_METHOD_MERCADO_PAGO_ARS ? !mercadoPagoEnabled : false}/>
                                                             disabled={!enableBeforePayment}/>
                                                      <span></span>{locales_es.gatewayMethodsList[pm.tag]['label_' + userData.user_type]} {pm.tag === PAYMENT_METHOD_PAYPAL_USD && <a href="https://www.youtube.com/watch?v=k34cen4zxmA" target="_blank">[Ver Video Tutorial]</a>}
                                                    </label>
                                                    {pm.tag === PAYMENT_METHOD_BANK_TRANSFER_ARS && this.PMIndexInArray(pm.id) >= 0 ?
                                                      <>
                                                        <br/>
                                                        <label className="float-right m-2">Información para la
                                                          transferencia
                                                          ({locales_es.required})</label>
                                                        <textarea className="form-control"
                                                                  disabled={!enableBeforePayment}
                                                                  onChange={(e) => this.setState({
                                                                    bankTransferData: e.target.value
                                                                  })}
                                                                  onBlur={() => onBlurBankTransferTextarea(pm.id)}
                                                                  defaultValue={this.state.bankTransferData}
                                                                  placeholder="Escribe aquí toda la información necesaria para que el paciente pueda realizar la transferencia correctamente"/>
                                                      </>
                                                      : null}
                                                    {pm.tag === PAYMENT_METHOD_MERCADO_PAGO_ARS ?
                                                      mercadoPagoEnabled === null ? <Spinner/>
                                                        :
                                                        <>
                                                          {mercadoPagoEnabled ?<div class="m-2"><button type="button"
                                                                                        disabled={!enableBeforePayment}
                                                                                        onClick={onClickUnlinkMercadoPago}
                                                                                        className="btn btn-danger btn-elevate btn-pill btn-sm m-sm-3 m-lg-0 ml-lg-3">Desvincular
                                                              Cuenta</button>
                                                              {mercadoPagoData &&
                                                                <p className="mt-3">Cuenta vinculada: {mercadoPagoData.nickname}</p>
                                                              }
                                                            </div>
                                                            : <button type="button"
                                                                      onClick={onClickLinkMercadoPago}
                                                                      className="btn btn-info btn-elevate btn-pill btn-sm m-sm-3 m-lg-0 ml-lg-3">Vincular
                                                              Cuenta de Mercado Pago</button>
                                                          }
                                                        </>
                                                      : null
                                                    }
                                                    {pm.tag === PAYMENT_METHOD_PAYPAL_USD && this.PMIndexInArray(pm.id) >= 0 ?
                                                      paypalCredentials === null ? <Spinner/>
                                                        :
                                                        paypalCredentials ?
                                                          <>
                                                            <br/>
                                                            <label className="float-right m-2">Client ID
                                                              ({locales_es.required})</label>
                                                            <input type="text"
                                                                   disabled={!enableBeforePayment}
                                                                   className="form-control"
                                                                   onChange={(e) => this.setPaypalCredentials('client_id', e.target.value)}
                                                                   value={this.state.paypalCredentials.client_id}
                                                                   placeholder="Escribe aquí el id de cliente de tu cuenta Paypal (Client ID)"/>
                                                            <label className="float-right m-2">Client Secret
                                                              ({locales_es.required})</label>
                                                            <input type="text"
                                                                   disabled={!enableBeforePayment}
                                                                   className="form-control"
                                                                   onChange={(e) => this.setPaypalCredentials('client_secret', e.target.value)}
                                                                   value={this.state.paypalCredentials.client_secret}
                                                                   placeholder="Escribe aquí el id de cliente de tu cuenta Paypal (Client ID)"/>
                                                          </>
                                                          : null
                                                      : null}
                                                    {pm.tag === PAYMENT_METHOD_BANK_TRANSFER_USD && this.PMIndexInArray(pm.id) >= 0 ?
                                                      <>
                                                        <br/>
                                                        <label className="float-right m-2">Información para la
                                                          transferencia en USD
                                                          ({locales_es.required})</label>
                                                        <textarea className="form-control"
                                                                  disabled={!enableBeforePayment}
                                                                  onChange={(e) => this.setState({
                                                                    bankTransferDataUSD: e.target.value
                                                                  })}
                                                                  onBlur={() => onBlurBankTransferUSDTextarea(pm.id)}
                                                                  defaultValue={this.state.bankTransferDataUSD}
                                                                  placeholder="Escribe aquí toda la información necesaria para que el paciente pueda realizar la transferencia correctamente"/>
                                                      </>
                                                      : null}
                                                  </div>
                                                </div>
                                              )
                                            :
                                            <div className="row">
                                              <div className="col">
                                                <h6 className="text-center">No hay métodos de pago habilitados</h6>
                                              </div>
                                            </div>
                                        }
                                      </>
                                      : null
                                  }
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        : <h1>Error: no se encontraron monedas activas.</h1>}
                    <div className="kt-portlet__foot kt-portlet__foot--md">
                      <div className="kt-widget-17__foot">
                        <div className="kt-widget-17__foot-info"/>
                        <div className="kt-widget-17__foot-toolbar">
                          <a href="#" onClick={save}
                             className="btn btn-brand btn-sm btn-upper btn-bold">{locales_es.save}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="tab3" role="tabpanel">
                    {userData &&
                      <PrescriptionSettings history={this.props.history} showMainModal={this.props.showMainModal}
                                            userData={userData}/>}
                  </div>
                  <div className="tab-pane fade" id="tab4" role="tabpanel">
                    {userData && <AttentionPlaces history={this.props.history} showMainModal={this.props.showMainModal} medic={userData} />}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        {result && (result === STATUS_SUCCESS || result === STATUS_FAILED) ?
          <ModalAnimationResult
            acceptAction={() => this.setState({result: null})}
            result={result}
            titleSuccess={'¡Tu cuenta ha sido vinculada con éxito!'}
            titleFailed={'Ha ocurrido un error al vincular tu cuenta'}
          />
          : null
        }
      </div>

    )
  }
}
