import React, { useEffect, useState } from 'react';
import './styles.css';
import whatsappIcon from './whatsapp-icon.svg';
import APIService from '../../modules/apiService';
import {HREF_PAGE_PLANS, PLAN_TAGS, USER_TYPE_PATIENT} from "../../models/constants";
import AuthService from "../../modules/authService";
import locales_es from "../../locales/es";
import ModalPlanSuggestion from "../../components/ModalPlanSuggestion";
import {useHistory} from "react-router-dom";

const WhatsAppButton = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const api = new APIService();
  const auth = new AuthService();
  const userType = auth.getLocalUserType();

  const history = useHistory();

  useEffect(() => {
    // Comprobar si ya se cerró el tooltip
    if (!localStorage.getItem('whatsappTooltipClosed')) {
      setShowTooltip(true);
    }
  }, []);

  const fetchUserPlan = async () => {
    if (userType === USER_TYPE_PATIENT) {
      return openWAChat();
    }
    try {
      const myPlanRes = await api.getMyPlan();
      const userPlan = myPlanRes.data;
      const hasSupportFeature = userPlan.features.some(
        feature => feature.tag === 'support-priority'
      );
      if (hasSupportFeature) {
        openWAChat();
      } else {
        setShowModal(true);
      }
    } catch (error) {
      console.error('Error al obtener el plan del usuario:', error);
    }
  };

  const openWAChat = () => {
    window.open(`https://wa.me/5491127014089?text=Hola,%20soy%20${locales_es.user_type[userType]}%20y%20tengo%20una%20consulta%20sobre%20el%20uso%20de%20la%20plataforma%20Medicloud`, '_blank');
  }

  const handleCloseTooltip = () => {
    setShowTooltip(false);
    localStorage.setItem('whatsappTooltipClosed', 'true');
  };

  const handleClick = (e) => {
      e.preventDefault();
      fetchUserPlan();
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const onConfirm = (planTag) => {
    history.push(HREF_PAGE_PLANS, {selectedPlan: planTag});
  }

  return (
    <div className="whatsapp-container">
      <a
        className="whatsapp-link"
        href="#"
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
      >
        <img className="whatsapp-icon" src={whatsappIcon} alt="WhatsApp" />
      </a>

      {showTooltip && (
        <div className="whatsapp-tooltip" onClick={handleCloseTooltip}>
          <span className="tooltip-close">&times;</span>
          ¡Contáctanos por cualquier duda que tengas, estamos para ayudarte!
        </div>
      )}

      {showModal && (
        <ModalPlanSuggestion
          title={locales_es.modalPlanSuggestion.titleWhatsAppButton}
          message={locales_es.modalPlanSuggestion.messageWhatsAppButton}
          plans={[PLAN_TAGS.PRO, PLAN_TAGS.ENTERPRISE]}
          onConfirm={onConfirm}
          onAbort={closeModal}/>
      )}
    </div>
  );
};

export default WhatsAppButton;
