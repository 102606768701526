import { Component } from "react";

class HotjarIntegration extends Component {
  constructor(props) {
    super(props);
    this.NEW_ACCOUNT_DAYS_THRESHOLD = 7; // Días para considerar una cuenta como nueva
  }

  componentDidMount() {
    const { user } = this.props;

    if (user && user.created_at) {
      const createdAtDate = new Date(user.created_at);
      const currentDate = new Date();
      const daysSinceCreated = Math.floor(
        (currentDate - createdAtDate) / (1000 * 60 * 60 * 24)
      );

      if (daysSinceCreated <= this.NEW_ACCOUNT_DAYS_THRESHOLD) {
        this.initializeHotjar();

        // Marca al usuario con Hotjar
        if (window.hj) {
          window.hj("identify", user.id, {
            email: user.email,
            created_at: user.created_at,
          });

          // Inicia el recording
          window.hj("trigger", "new_account");
        }
      } else {
        console.log("La cuenta no es nueva. Hotjar no se activará.");
      }
    }
  }

  initializeHotjar() {
    if (window.hj) {
      console.log("Hotjar ya está inicializado.");
      return;
    }

    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 3116149, hjsv: 6 };
      a = o.getElementsByTagName("head")[0];
      r = o.createElement("script");
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
  }

  render() {
    return null; // Este componente no renderiza nada en el DOM
  }
}

export default HotjarIntegration;
